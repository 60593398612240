<template>
  <section class="banner">
    <div class="banner-container">
        <h1 v-html="$t('home.title')"></h1> 
        <h2 v-html="$t('home.subtitle')"></h2>
    </div>
  </section>
  <section class="blog-home">
    <blog-home></blog-home>
  </section>
  <div class="sep"></div>
  <!-- SECTION INDUS -->
  <section class="section-default">
      <div class="col-bg indus"></div>
      <div class="col-sep"></div>
      <div class="col-text">
        <h3>{{$t('home.title1')}}</h3>
        <p v-html="$t('home.text1')"></p>
        <p v-html="$t('home.text2')"></p>
      </div>
  </section>
  <!-- SECTION WORKSHOP -->
  <section class="section-default reverse">
      <div class="col-bg workshop"></div>
      <div class="col-sep"></div>
      <div class="col-text">
        <h3>{{$t('home.title2')}}</h3>
        <p v-html="$t('home.text3')"></p>
      </div>
  </section>
  <!-- SECTION ADDITIVE -->
  <!-- <section class="section-default">
      <div class="col-bg">
        <img src="./../assets/addictive.png" alt="addictive" />
      </div>
      <div class="col-sep"></div>
      <div class="col-text">
        <h3>{{$t('home.title3')}}</h3>
        <p v-html="$t('home.text4')"></p>
        <p v-html="$t('home.text5')"></p>
      </div>
  </section> -->
  <!-- SECTION IMPLANTS -->
  <section class="section-default">
      <div class="col-bg implants"></div>
      <div class="col-sep"></div>
      <div class="col-text">
        <h3>{{$t('home.title4')}}</h3>
        <p v-html="$t('home.text6')"></p>
      </div>
  </section>
  <!-- SECTION COJNTROLLER -->
  <section class="section-default reverse">
      <div class="col-bg controller"></div>
      <div class="col-sep"></div>
      <div class="col-text">
        <h3>{{$t('home.title5')}}</h3>
        <p v-html="$t('home.text7')"></p>
        <p v-html="$t('home.text8')"></p>
      </div>
  </section>
  <!-- SECTION CLEANER -->
  <section class="section-default">
      <div class="col-bg cleaner"></div>
      <div class="col-sep"></div>
      <div class="col-text">
        <h3>{{$t('home.title6')}}</h3>
        <p v-html="$t('home.text9')"></p>
        <p v-html="$t('home.text10')"></p>
      </div>
  </section>
  <!-- SECTION CONSTRUCT -->
  <section class="section-default reverse">
      <div class="col-bg construct"></div>
      <div class="col-sep"></div>
      <div class="col-text">
        <h3 v-html="$t('home.title7')"></h3>
        <p v-html="$t('home.text11')"></p>
        <p v-html="$t('home.text12')"></p>
      </div>
  </section>
  <div class="sep"></div>
</template>

<script>
import BlogHome from "./../components/BlogHome.vue";

export default {
  name: 'Home',
  components: {
    BlogHome
  }
}
</script>

<style scoped lang="scss">

.banner {
  min-height: 100vh;
  background-image: url("./../assets/bg-banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-container {
 padding-top:350px;
}

.banner-container h1 {
  padding:40px 40px 40px 150px;
  background:$mainColor;
  font-size:35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: right;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color:#fff;
  margin: 0;
  z-index: 1;
  position: relative;
  width:600px;
 
}

.banner-container h2 {
  padding:80px 40px 40px 60px;
  background:rgba($color: #fff, $alpha: 0.8);
  font-size:18px;
  font-weight: 400;
  text-align: right;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color:$black;
  margin: -40px 0 0 0;
  width:800px;
}

.section-default {
  display:flex;
  padding:40px 0;
  width:100%;
  align-items: center;
  justify-content: space-between;
  min-height: 450px;
}

.section-default.reverse {
  flex-direction: row-reverse;
}

.col-bg {
  width:calc(45% - 2px);  
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-bg img {
  max-width: 80%;
  margin-left:5%;
}

.col-text  {
  padding-right: 10%;
  width:calc(35% - 2px); 
}

.reverse .col-text {
  padding-right: 0;
  padding-left: 10%;
}

.reverse p, .reverse h3 {
  text-align: right;
}

.col-sep {
  height:200px;
  width:4px;
  background:$mainColor;
}

.indus {
  background-image: url("./../assets/bg-indus.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.workshop {
  background-image: url("./../assets/bg-workshop.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.implants {
  background-image: url("./../assets/bg-implants.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.controller {
  background-image: url("./../assets/bg-controller.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cleaner {
  background-image: url("./../assets/bg-cleaner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.construct  {
  background-image: url("./../assets/bg-construct.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h3 {
  color:$mainColor;
  font-size:30px;
  font-weight:700;
  margin: 0 0 40px 0;
}

.sep {
  height:120px;
  width:100%;
}

@media screen and (max-width:1024px) {
  .banner-container {
    padding-top:150px;
    padding-bottom:150px;
  }

  .banner-container h1 {
    padding:20px 40px;
    width:calc(100% - 80px);
    background:$mainColor;
    font-size:25px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color:#fff;
    margin: 0;
    z-index: 1;
    position: relative;
  
  }

  .banner-container h2 {
    padding:20px;
    width:calc(100% - 40px);
    background:rgba($color: #fff, $alpha: 0.8);
    font-size:15px;
    font-weight: 400;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color:$black;
    margin: -10px 0 0 0;
  }

  .banner {
    min-height: auto;
  }

}


@media screen and (max-width:768px) {
  .col-bg {
    width:100%;
    margin-bottom: 20px;
    height:300px;
    min-height: 300px;
  }

  .col-text {
    width:calc(90% - 45px);
  }

  .col-sep {
    margin:0 20px;
  }

  .section-default {
    flex-wrap: wrap;
  }

  h3 {
    font-size:25px;
    margin-bottom:20px;
  }

  .col-bg img {
      max-width: 100%;
      margin-left: 5%;
  }
}

</style>
