<template>
    <div class="blog-home-container">
        <no-posts v-if="!posts.length"></no-posts>
        <template v-else>
            <div class="post-item" v-for="(item, idx) in posts" :key="idx" @click="openPost(item)">
                <div class="img-container">
                    <img :src="`${$store.state.assetsUrl}/front-${item.imgFeatured}`" class="img-thumb" />
                    <div class="post-type">{{ getType(item.cat)}}</div>
                    <div class="title-container">
                        <h3>{{item.title}}</h3>
                    </div>
                </div>               
            </div>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import NoPosts from "./NoPosts.vue";

export default {
    name : "Blog",
    components : { NoPosts},
    data() {
        return {
            posts: [],
        }
    },
    mounted() {
        this.getData();
    },
    methods : {
        getType(cat) {
            let typeStr = "";
            switch(cat) {
                case 1 : 
                    typeStr = this.$t("news.news");
                    break;
                case 2 : 
                    typeStr = this.$t("news.events");
                    break;
                case 3 : 
                    typeStr = this.$t("news.pressRelease");
                    break;
                case 4 : 
                    typeStr = this.$t("news.pressReview");
                    break;
                case 5 : 
                    typeStr = this.$t("news.career");
                    break;
                case 6 : 
                    typeStr = this.$t("news.docs");
                    break;
                case 7 : 
                    typeStr = this.$t("news.shareholdersMeeting");
                    break;
            }
            return typeStr;
        },
        getData() {
            let config = {
                validateStatus: () => true,  
            };
            let vm = this;

            axios
                .get(`${this.$store.state.apiUrl}/public/posts/3/all/${this.$i18n.locale}/1/4`,  config)
                .then(function (res) {                
                    if(res.status != 200) {
                        console.log('Error => ' + res.data.errors)                  
                    }else{
                        vm.posts = res.data.message;
                    }
                });
        },
        openPost(post) {
            //si standar ou verture de la page puis populated
            if(post.type == 1) {
                this.$router.push({name : "BlogItem", params : { post : post.id}});
            }
            //si lien dof pdf on le DL
            else if(post.type == 2) {
                window.open(`${this.$store.state.apiUrl}/public/pdfs/${post.pdf}`, '_blank');
            }
            //sinon lien externe
            else {
                window.open(post.link, "_blank");
            }
        },
    },
    watch : {
        "$i18n.locale" : function() {
            this.getData();
        }
    }
}
</script>

<style lang="scss" scoped>


    .blog-home-container {
        width:100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .post-item {
        width:25%;
        margin:0;
        overflow: hidden;
    }

    .img-container {
        position: relative;
        width:100%;
        overflow: hidden;
        max-height: calc(100% - 5px);
    }

    .img-container img {
        max-width: 100%;
        cursor: pointer;
        transition: 0.5s;
    }

    .img-container img:hover {
        transform: scale(1.1);
    }

    .post-type {
        position:absolute;
        top:0;
        left:0;
        padding:10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        font-size:15px;
        font-weight: 300;
        background:rgba($mainColor, 0.8);
        text-align:center;
    }

    .title-container {
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        height : 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background:rgba(#fff, 0.8);
        text-align:center; 
    }


    h3 {
        color:$black;
        font-size:15px;
        font-weight:400;
        text-align: left;
        padding:0 10px;
    }


    @media screen and (max-width:1000px) {
        .post-item {
            width:50%;
        }

        .post-type {
            font-size:13px;
            width:100px;
            height:40px;
        }

        h3 {
            font-size:15px;
        }

    }

    @media screen and (max-width:500px) {
    .post-item {
            width:100%;
        }
    }
</style>